import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import ICheckout from '../services/checkout/interfaces/ICheckoutDTO';
import PaymentService from '../services/payment';
import ICheckoutPaymentDTO, {
  BillingInformation,
  ICheckoutCreditCardPayment,
  ICheckoutTicketPayment,
  PaymentMethods,
} from '../services/payment/interfaces/ICheckoutPaymentDTO';
import CheckoutService from '../services/checkout/CheckoutService';

interface IPaymentContextData {
  setCvv(value: string): void;
  setNumCard(value: string): void;
  setNameCard(value: string): void;
  setValidate(value: string): void;
  setCheckoutId(value: string): void;
  setUserProfile(value: string): void;
  setUserPaymentProfile(value: string): void;
  setCheckoutPayment(obj: ICheckout): void;
  setPaymentMethod(value: PaymentMethods): void;
  handleSubmitPayment(): void;
  setCardBrand(value: string): void;
  setInstallment(value: number): void;
  setBillingInformation(value?: BillingInformation): void;
  setSelectedFile(value: FormData | null): void;
  setPaymentOrderNum(value: string): void;
  handleSubmitPaymentCheckout(status: string): void;

  paymentMethod: PaymentMethods;
  btnConfirm?: boolean;
  cvv: string;
  numCard: string;
  nameCard?: string;
  validate: string;
  userProfile?: string;
  userPaymentProfile?: string;
  checkoutHook?: ICheckout;
  cardBrand?: string;
  installment: number;
  billingInformation: BillingInformation | undefined;
  paymentOrderNumber: string;
  selectedFile: FormData | null;
}

interface IPaymentsProps {
  children: JSX.Element[] | JSX.Element;
}

const PaymentContext = createContext<IPaymentContextData>(
  {} as IPaymentContextData,
);

export const PaymentProvider = ({ children }: IPaymentsProps): JSX.Element => {
  const { t } = useTranslation('checkout');

  const [btnConfirm, setBtnConfirm] = useState(true);
  const [cvv, setCvv] = useState('');
  const [numCard, setNumCard] = useState('');
  const [nameCard, setNameCard] = useState('');
  const [validate, setValidate] = useState('');
  const [checkoutId, setCheckoutIdState] = useState('');
  const [checkoutHook, setCheckoutHook] = useState({} as ICheckout);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    PaymentMethods.CREDIT,
  );
  const [cardBrand, setCardBrand] = useState('');
  const [installment, setInstallment] = useState(1);
  const [billingInformation, setBillingInformation] = useState<
    BillingInformation | undefined
  >(undefined);

  const [userProfile, setUserProfile] = useState('');
  const [userPaymentProfile, setUserPaymentProfile] = useState('');
  const [paymentOrderNumber, setPaymentOrderNumber] = useState('');
  const { goBack } = useHistory();
  const [selectedFile, setSelectedFile] = useState<FormData | null>(null);
  const [paymentOrderNum, setPaymentOrderNum] = useState('');

  const handleSubmitCreditCard = useCallback(async () => {
    let paymentData: ICheckoutCreditCardPayment = {
      cardExpiration: validate,
      cardHolderName: nameCard,
      checkoutID: checkoutId,
      userProfile,
      userPaymentProfile,
      installment,
      billingInformation,
      cardNumber: numCard,
      cardCode: cvv,
    };

    const { gateway } = checkoutHook;

    if (/^JPMorgan/i.test(gateway) && !userPaymentProfile) {
      const jpmorgan: any = window;
      if (jpmorgan?.ProtectPANandCVV) {
        const encrypted = jpmorgan.ProtectPANandCVV(numCard, cvv, false);
        paymentData = {
          ...paymentData,
          cardNumber: encrypted[0],
          cardCode: encrypted[1],
          cardEncryptionKey: encrypted[2],
        };
      }
    }

    return PaymentService.sendToCreditPayment(paymentData);
  }, [
    validate,
    nameCard,
    checkoutId,
    userProfile,
    userPaymentProfile,
    installment,
    billingInformation,
    numCard,
    cvv,
    checkoutHook,
  ]);

  const handleSubmitDebit = useCallback(async () => {
    const paymentData: ICheckoutPaymentDTO = {
      cardNumber: numCard,
      cardExpiration: validate,
      cardHolderName: nameCard,
      cardCode: cvv,
      checkoutID: checkoutId,
    };

    return PaymentService.sendToDebitPayment(paymentData);
  }, [cvv, checkoutId, nameCard, numCard, validate]);

  const handleSubmitTicketRegistration = useCallback(async () => {
    const paymentData: ICheckoutTicketPayment = {
      checkoutID: checkoutId,
    };
    return PaymentService.sendToTicketRegistration(paymentData);
  }, [checkoutId]);

  const handleSubmitWireTransferPayment = useCallback(async (file) => {
    return PaymentService.attachReceipt(file);
  }, []);

  const handleSubmitPayment = useCallback(async () => {
    Swal.fire({
      title: t('Please Wait!'),
      text: t('We are processing your payment.'),
      icon: 'info',
      showConfirmButton: false,
    });

    try {
      let r: AxiosResponse | undefined;

      let blob;
      let url;
      let link;

      switch (paymentMethod) {
        case PaymentMethods.CREDIT:
          r = await handleSubmitCreditCard();
          break;
        case PaymentMethods.DEBIT:
          r = await handleSubmitDebit();
          break;
        case PaymentMethods.WIRE_TRANSFER:
          if (selectedFile && selectedFile.get('voucher')) {
            selectedFile.append('checkoutId', checkoutId);
            selectedFile.append(
              'transactionType',
              PaymentMethods.WIRE_TRANSFER,
            );
            selectedFile.append('transactionNumber', paymentOrderNum);
            r = await handleSubmitWireTransferPayment(selectedFile);
          }

          break;
        case PaymentMethods.TICKET:
          r = await handleSubmitTicketRegistration();
          blob = new Blob([r.data], { type: 'application/pdf' });
          url = window.URL.createObjectURL(blob);
          link = document.createElement('a');
          link.href = url;
          // eslint-disable-next-line prefer-destructuring
          link.download =
            r.headers['content-disposition'].split('filename=')[1];
          link.click();
          link.remove();
          break;
        default:
          r = undefined;
          break;
      }

      if (!r) {
        Swal.fire({
          title: 'Oops!',
          text: `Payment method ${paymentMethod} unknown`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }

      Swal.close();
      Swal.fire({
        title: 'Yeah!',
        text: r?.data.message,
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        window.location.href = checkoutHook.successUrl;
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.response);
      let message = t('Error trying to pay checkout!');
      if (e.response?.data.message === 'Missing customer CNPJ in checkout') {
        message = t(e.response.data.message);
      } else if (e.response) {
        message = e.response.data.message;
      }
      Swal.close();
      Swal.fire({
        title: 'Oops!',
        text: message,
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        window.location.href = checkoutHook.errorUrl;
      });
    }
  }, [
    t,
    paymentMethod,
    handleSubmitCreditCard,
    handleSubmitDebit,
    selectedFile,
    handleSubmitTicketRegistration,
    checkoutId,
    paymentOrderNum,
    handleSubmitWireTransferPayment,
    checkoutHook.successUrl,
    checkoutHook.errorUrl,
  ]);

  const handleSubmitPaymentCheckout = useCallback(
    async (status) => {

      const r = await CheckoutService.getCheckout(checkoutId);
      
      if(r.data.body.decision) {
        return
      }

      const steps = Swal.getQueueStep();

      if (steps) return;

      Swal.queue([
        {
          title: t('Please Wait!'),
          text: t('We are processing your payment.'),
          icon: 'info',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        },
      ]);

      try {
        const r = await PaymentService.sendToCheckoutPayment({
          checkoutId,
          status,
        });

        if([202].includes(r.status)) {
          return
        }

        Swal.deleteQueueStep(0);

        Swal.queue([
          {
            title: 'Yeah!',
            text: r?.data.message,
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: () => {
              if (['invoices'].includes(checkoutHook.application)) {
                window.history.back();
              }

              window.location.href = checkoutHook.successUrl;
            },
          },
        ]);
      } catch (e) {
        // eslint-disable-next-line no-console
        let message = t('Error trying to pay checkout!');
        if (e.response?.data.message === 'Payment Unsuccessful') {
          message = t('Error trying to pay checkout!');
        } else if (e.response) {
          message = e.response.data.message;
        }

        Swal.deleteQueueStep(0);

        Swal.queue([
          {
            title: 'Oops!',
            text: message,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: () => {
              if (['invoices'].includes(checkoutHook.application)) {
                window.history.back();
              }

              window.location.href = checkoutHook.errorUrl;
            },
          },
        ]);
      }
    },
    [
      t,
      checkoutId,
      checkoutHook.application,
      checkoutHook.successUrl,
      checkoutHook.errorUrl,
    ],
  );

  const handleCreatePaymentOrder = useCallback(
    async (checkoutID: string) => {
      try {
        const {
          data: {
            body: { paymentOrder },
          },
        } = await PaymentService.createPaymentOrder({
          checkoutId: checkoutID,
        });

        setPaymentOrderNumber(paymentOrder.orderId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify(err));
        Swal.fire({
          title: 'Oops!',
          text:
            (err as AxiosError)?.response?.data.message ||
            t('Error trying to get bank information'),
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          goBack();
        });
      }
    },
    [goBack, t],
  );

  const setCheckoutId = useCallback((id: string) => {
    setCheckoutIdState(id);
  }, []);

  const setCheckoutPayment = useCallback((obj: ICheckout) => {
    setCheckoutHook(obj);
  }, []);

  useEffect(() => {
    const numCardFormat = numCard.replace(/([^\d])+/gim, '');
    const validateFormat = validate.replace(/([^\d])+/gim, '');
    const cvvFormat = cvv.replace(/([^\d])+/gim, '');

    if (
      (nameCard.toString().length > 5 &&
        cvvFormat.toString().length >= 3 &&
        numCardFormat.toString().length >= 14 &&
        validateFormat.toString().length === 6) ||
      (userProfile && userPaymentProfile && cvvFormat.toString().length >= 3) ||
      paymentMethod === 'TICKET'
    ) {
      setBtnConfirm(false);
    } else {
      setBtnConfirm(true);
    }

    if (paymentMethod === 'WIRE_TRANSFER' && !paymentOrderNumber) {
      handleCreatePaymentOrder(checkoutId);
    }

    if (checkoutHook.transactionType === 'WIRE_TRANSFER') {
      setPaymentMethod(PaymentMethods.WIRE_TRANSFER);
    }
  }, [
    cvv,
    nameCard,
    numCard,
    validate,
    userProfile,
    userPaymentProfile,
    paymentMethod,
    handleCreatePaymentOrder,
    checkoutId,
    paymentOrderNumber,
    checkoutHook.transactionType,
  ]);

  return (
    <PaymentContext.Provider
      value={{
        handleSubmitPayment,
        btnConfirm,
        cvv,
        setCvv,
        numCard,
        setNumCard,
        nameCard,
        setNameCard,
        validate,
        setValidate,
        setCheckoutId,
        setCheckoutPayment,
        checkoutHook,
        setUserPaymentProfile,
        userPaymentProfile,
        setUserProfile,
        userProfile,
        setPaymentMethod,
        paymentMethod,
        setCardBrand,
        cardBrand,
        installment,
        setInstallment,
        setBillingInformation,
        billingInformation,
        paymentOrderNumber,
        setSelectedFile,
        setPaymentOrderNum,
        selectedFile,
        handleSubmitPaymentCheckout,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export function usePayment(): IPaymentContextData {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error('usePayment must be used within an PaymentProvider');
  }

  return context;
}
