import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';

import type ICheckout from '../../../services/checkout/interfaces/ICheckoutDTO';
import JPMorganCheckout from '../../../utils/jpmorgan-checkout';
import { usePayment } from '../../../hooks/payment';

export interface ICheckoutPaymentData {
  checkout: ICheckout;
}

type AnnouncementData = {
  level: 'error' | 'warn' | 'info';
  namespace: string;
  message?: string;
  Payload?: any;
};

const CheckoutPayment = ({ checkout }: ICheckoutPaymentData): JSX.Element => {
  const { handleSubmitPaymentCheckout } = usePayment();

  const handlerCheckoutPayment = useCallback(
    async (announcement: AnnouncementData) => {
      if (announcement.namespace === 'payment' && announcement.message) {
        if (announcement.level === 'info') {
          if (
            ['PaymentSuccess', 'PaymentUnsuccessful'].includes(
              announcement.message,
            )
          ) {
            handleSubmitPaymentCheckout(announcement.message);
            await new Promise((resolve) => setTimeout(resolve, 2000))
          }
        }
      }
    },
    [handleSubmitPaymentCheckout],
  );

  useEffect(() => {
    JPMorganCheckout(() => {
      const { DropInUI } = window as any;

      const checkoutDrop = new DropInUI({
        checkoutSessionToken: checkout.session,
      });

      checkoutDrop.mount('checkout-container');

      checkoutDrop.subscribe(handlerCheckoutPayment);
    });
  }, [checkout, handlerCheckoutPayment]);

  return (
    <>
      <Grid item xs={12}>
        <div id="checkout-container" />
      </Grid>
    </>
  );
};

export default CheckoutPayment;
